import { render, staticRenderFns } from "./RiveLogo.vue?vue&type=template&id=b5c08e9c&"
import script from "./RiveLogo.vue?vue&type=script&lang=js&"
export * from "./RiveLogo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports